import React, { useEffect, useState } from 'react';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import { useQueryParam, NumberParam } from 'use-query-params';
import { graphql, useStaticQuery } from 'gatsby';

import { Navigation } from 'utilities/components/navigation/component';
import { Footer } from 'utilities/components/footer/component';
import { Breadcrumbs } from 'utilities/components/breadcrumbs/view';
import InstagramFeed from './instagramFeed';
import { routes } from 'utilities/routes';
import { MESSENGER_APP_ID, MESSENGER_PAGE_ID } from 'utilities/consts';
import MessengerCustomerChat from 'react-messenger-customer-chat';

import LogRocket from 'logrocket';

import 'features/realizations/viev.scss';
import SEO from 'utilities/components/seo';
import { useSeoTexts } from 'utilities/hooks/useSeoTexts';
import { SeoDecription } from 'features/index/seo-description/view';

LogRocket.init('qijaj0/baranski');

const Realizations = () => {
    const [realizationId] = useQueryParam('id', NumberParam);

    const data = useStaticQuery(graphql`
        query GetAllRealizations {
            allRealizations {
                nodes {
                    accessory
                    addon
                    alternative_id
                    color
                    frame
                    glass
                    images
                    link_product
                    main_image
                    alt_main_image
                    name
                    date
                    trims {
                        image
                        name
                    }
                    lines {
                        brands {
                            name
                            type
                        }
                        name
                    }
                }
            }
        }
    `);

    const [realization, setRealization] = useState(null);

    const [realizations, setRealizations] = useState(null);

    useEffect(() => {
        setRealizations(() => data?.allRealizations?.nodes);
    }, []);

    useEffect(() => setRealization(() => realizations?.find((item) => item?.alternative_id === realizationId)), [
        realizationId,
        realizations
    ]);

    const breadcrumbs = [routes.home, routes.realizacje];

    const [areFiltersVisible] = useState(false);

    useEffect(() => {
        if (areFiltersVisible) {
            disableBodyScroll();
        } else {
            enableBodyScroll();
        }
    }, [areFiltersVisible]);

    const setCanonicalLink = () => {
        let relCanonical = '';
        if (typeof window !== 'undefined') {
            relCanonical = `${window.location.origin}${window.location.pathname}`;
        }
        return relCanonical;
    };
    const { metaTitle, metaDescription, description } = useSeoTexts('Realizacje');

    return (
        <main className="realizacje">
            <SEO
                noindex
                title={
                    realization?.name
                        ? `${realization?.name} - ${realization?.lines?.name}`
                        : metaTitle || 'Barański Drzwi - Realizacje - Drzwi wewnętrzne, drzwi zewnętrzne i inne'
                }
                description={
                    realization?.name
                        ? `${realization?.lines?.brands?.name} - ${realization?.lines?.brands?.type} - ${realization?.date}`
                        : metaDescription
                          || 'Poznaj nasze realizacje drzwi wewnętrznych z ościeżnicą, drzwi zewnętrznych drewnianych oraz innych, niestandardowych projektów.'
                }
                image={ realization?.main_image ? `${process.env.GATSBY_URL}/${realization?.main_image}` : null }
                alt={ realization?.name ? realization?.name : 'Realizacje' }
                canonicalLink={ setCanonicalLink() }
            />
            <Navigation />
            <Breadcrumbs breadcrumbs={ breadcrumbs } />
            <InstagramFeed refId="d6efeaf0c3e70731e763662b049212677558503b" />     
            <SeoDecription description={ description } />
            <Footer />
            <MessengerCustomerChat pageId={ MESSENGER_PAGE_ID } appId={ MESSENGER_APP_ID } />
        </main>
    );
};

export default Realizations;
